import type {ReactElement} from 'react';
import React from 'react';
import type {SocialPost} from '@Components/social-media/post.vo';
import {Text, TextSize} from '@Components/text';
import {ceil} from 'lodash';
import {Icon} from '@Components/icon-v2';
import type {FlatIconType} from '@Components/icon-v2/icon.types';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {AllMetricKeys, BaseMetrics} from '@Components/social-media/social-platform-metrics.types';
import {
  metricsBase,
  metricsFacebook,
  metricsInstagramPost,
  metricsInstagramVideo,
  metricsLinkedInPage,
  metricsPinterest,
  metricsTikTok,
  metricsTwitter,
  metricsYouTube,
} from '@Components/social-media/social-platform-metrics.types';
import {
  getBaseIconTooltipByIndex,
  getBaseMetricDescriptionByIndex,
  getBaseMetricIconByIndex,
  getFacebookIconTooltipByIndex,
  getFacebookMetricDescriptionByIndex,
  getFacebookMetricIconByIndex,
  getFacebookMetricsByIndex,
  getInstagramDescriptionByIndex,
  getInstagramIconTooltipByIndex,
  getInstagramMetricIconByIndex,
  getInstagramMetricsByIndex,
  getInstagramMetricsKey,
  getLinkedInIconTooltipByIndex,
  getLinkedInMetricDescriptionByIndex,
  getLinkedinMetricIconByIndex,
  getLinkedinMetricsByIndex,
  getPinterestIconTooltipByIndex,
  getPinterestMetricDescriptionByIndex,
  getPinterestMetricIconByIndex,
  getPinterestMetricsByIndex,
  getReadableMetrics,
  getTikTokIconTooltipByIndex,
  getTikTokMetricDescriptionByIndex,
  getTikTokMetricIconByIndex,
  getTikTokMetricsByIndex,
  getTooltipReadableMetrics,
  getTwitterIconTooltipByIndex,
  getTwitterMetricDescriptionByIndex,
  getTwitterMetricIconByIndex,
  getTwitterMetricsByIndex,
  getYouTubeIconTooltipByIndex,
  getYouTubeMetricDescriptionByIndex,
  getYouTubeMetricIconByIndex,
  getYouTubeMetricsByIndex,
} from '@Components/social-media/social-media-metrics-library';
import {getReadableAccounts, SocialPlatform} from '@Components/social-media/social-media.types';
import {useUserGuidelineType} from '@Panels/social-posts-dialog-panel/social-posts-dialog-panel.hooks';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import type {SocialAccount} from '@Components/social-media/account.vo';
import {getDateFromUnixTimestamp, getTimeToOrFrom} from '@Utils/date.util';
import {mapIndexToAccountPublishingParams} from '@Libraries/social-media';
import styles from './social-post-dialog-metrics-grid.module.scss';

interface SocialPostDialogMetricsGridProps {
  activeAccountIndex: number;
  socialPost: SocialPost;
}

export enum SocialPostUserGuidelines {
  PERFORMANCE_LIMITATIONS = 'PERFORMANCE_LIMITATIONS',
  STORY_PERFORMANCE_LIMITATIONS = 'STORY_PERFORMANCE_LIMITATIONS',
  POPULATING = 'POPULATING',
  ERROR = 'ERROR',
  PRIVATE_POSTS = 'PRIVATE_POSTS',
  UPGRADE_PREMIUM = 'UPGRADE_PREMIUM',
  UNPUBLISHED_POST = 'UNPUBLISHED_POST',
  NO_GUIDELINES = 'NO_GUIDELINES',
  PERMISSION_PENDING = 'PERMISSION_PENDING',
}

export interface MetricMeta {
  value: number;
  type: AllMetricKeys;
}

export function SocialPostDialogMetricsGrid({activeAccountIndex, socialPost}: SocialPostDialogMetricsGridProps): ReactElement {
  const numberMetricsPerRow = 2;
  const userGuidelineType = useUserGuidelineType();

  const getFlatIconColor = (): FlatIconColor => {
    if (isDefaultSelection() && socialPost.accounts.length > 1) {
      return FlatIconColor.SECONDARY;
    }

    return FlatIconColor.PRIMARY;
  };

  const isMultiPosting = (): boolean => {
    if (socialPost.posterHashedIds.length > 1) {
      return true;
    }

    return false;
  };

  const shouldShowReelMetricsForInstagram = (): boolean => {
    const firstPosterId = socialPost.posterHashedIds[0];
    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;
    const {platformMediaType} = publishingParamsInformation;
    const graphicsMetaForPoster = socialPost.publishingParams[account.id][platformMediaType];

    if (!graphicsMetaForPoster) {
      return false;
    }

    return graphicsMetaForPoster.graphicsMeta[firstPosterId].isVideo && !isMultiPosting();
  };

  const getBaseMetrics = (metricIndex: number): number => {
    if (!socialPost.metrics) {
      return 0;
    }

    let metricSum = 0;
    const metricKey = metricsBase[metricIndex];
    for (let i = 0; i < socialPost.accounts.length; i++) {
      const accountId = socialPost.accounts[i].id;
      const metricsForIthAccount = socialPost.metrics[accountId] as BaseMetrics;
      const value: number | undefined = !metricsForIthAccount ? 0 : metricsForIthAccount[metricKey];

      metricSum += value === undefined ? 0 : value;
    }

    return metricSum;
  };

  const getMetricMeta = (metricIndex: number): MetricMeta => {
    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    if (!socialPost.metrics) {
      return {
        value: 0,
        type: undefined,
      };
    }

    if (isDefaultSelection() && socialPost.accounts.length > 1) {
      return {
        value: getBaseMetrics(metricIndex),
        type: undefined,
      };
    }

    const socialPostMetrics = socialPost.metrics[account.id];

    if (!socialPostMetrics) {
      return {
        value: 0,
        type: undefined,
      };
    }

    switch (account.type) {
      case SocialPlatform.FACEBOOK_PROFILE:
      case SocialPlatform.FACEBOOK:
      case SocialPlatform.FACEBOOK_PAGE:
      case SocialPlatform.FACEBOOK_GROUP:
        return {
          value: getFacebookMetricsByIndex(socialPostMetrics, metricIndex),
          type: metricsFacebook[metricIndex],
        };

      case SocialPlatform.TWITTER:
        return {
          value: getTwitterMetricsByIndex(socialPostMetrics, metricIndex),
          type: metricsTwitter[metricIndex],
        };

      case SocialPlatform.INSTAGRAM:
        return {
          value: getInstagramMetricsByIndex(socialPostMetrics, metricIndex, shouldShowReelMetricsForInstagram()),
          type: getInstagramMetricsKey(shouldShowReelMetricsForInstagram(), metricIndex),
        };

      case SocialPlatform.TIKTOK:
        return {
          value: getTikTokMetricsByIndex(socialPostMetrics, metricIndex),
          type: metricsTikTok[metricIndex],
        };

      case SocialPlatform.YOUTUBE:
        return {
          value: getYouTubeMetricsByIndex(socialPostMetrics, metricIndex),
          type: metricsYouTube[metricIndex],
        };

      case SocialPlatform.LINKEDIN:
      case SocialPlatform.LINKEDIN_PAGE:
      case SocialPlatform.LINKEDIN_PROFILE:
        return {
          value: getLinkedinMetricsByIndex(socialPostMetrics, metricIndex),
          type: metricsLinkedInPage[metricIndex],
        };

      case SocialPlatform.PINTEREST:
        return {
          value: getPinterestMetricsByIndex(socialPostMetrics, metricIndex),
          type: metricsPinterest[metricIndex],
        };

      default:
        return {
          value: 0,
          type: undefined,
        };
    }
  };

  const isDefaultSelection = (): boolean => {
    return activeAccountIndex === -1;
  };

  const getMetricIcon = (metricIndex: number): string => {
    if (isDefaultSelection() && socialPost.accounts.length > 1) {
      return getBaseMetricIconByIndex(metricIndex);
    }

    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    if (!socialPost.metrics) {
      return '';
    }

    const socialPostMetrics = socialPost.metrics[account.id];

    if (!socialPostMetrics) {
      return '';
    }

    switch (account.type) {
      case SocialPlatform.FACEBOOK_PROFILE:
      case SocialPlatform.FACEBOOK:
      case SocialPlatform.FACEBOOK_PAGE:
      case SocialPlatform.FACEBOOK_GROUP:
        return getFacebookMetricIconByIndex(metricIndex);

      case SocialPlatform.TWITTER:
        return getTwitterMetricIconByIndex(metricIndex);

      case SocialPlatform.INSTAGRAM:
        return getInstagramMetricIconByIndex(metricIndex, shouldShowReelMetricsForInstagram());

      case SocialPlatform.TIKTOK:
        return getTikTokMetricIconByIndex(metricIndex);

      case SocialPlatform.YOUTUBE:
        return getYouTubeMetricIconByIndex(metricIndex);

      case SocialPlatform.LINKEDIN:
      case SocialPlatform.LINKEDIN_PROFILE:
      case SocialPlatform.LINKEDIN_PAGE:
        return getLinkedinMetricIconByIndex(metricIndex);

      case SocialPlatform.PINTEREST:
        return getPinterestMetricIconByIndex(metricIndex);

      default:
        return '';
    }
  };

  const getMetricText = (metricIndex: number): string => {
    if (isDefaultSelection() && socialPost.accounts.length > 1) {
      return getBaseMetricDescriptionByIndex(metricIndex);
    }

    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    if (!socialPost.metrics) {
      return '';
    }

    const socialPostMetrics = socialPost.metrics[account.id];

    if (!socialPostMetrics) {
      return '';
    }

    switch (account.type) {
      case SocialPlatform.FACEBOOK_PROFILE:
      case SocialPlatform.FACEBOOK:
      case SocialPlatform.FACEBOOK_PAGE:
      case SocialPlatform.FACEBOOK_GROUP:
        return getFacebookMetricDescriptionByIndex(metricIndex);

      case SocialPlatform.TWITTER:
        return getTwitterMetricDescriptionByIndex(metricIndex);

      case SocialPlatform.INSTAGRAM:
        return getInstagramDescriptionByIndex(metricIndex, shouldShowReelMetricsForInstagram());

      case SocialPlatform.TIKTOK:
        return getTikTokMetricDescriptionByIndex(metricIndex);

      case SocialPlatform.YOUTUBE:
        return getYouTubeMetricDescriptionByIndex(metricIndex);

      case SocialPlatform.LINKEDIN:
      case SocialPlatform.LINKEDIN_PROFILE:
      case SocialPlatform.LINKEDIN_PAGE:
        return getLinkedInMetricDescriptionByIndex(metricIndex);

      case SocialPlatform.PINTEREST:
        return getPinterestMetricDescriptionByIndex(metricIndex);

      default:
        return '';
    }
  };

  const getIconTooltipByAccountSelection = (metricIndex: number): string => {
    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    if (isDefaultSelection() && socialPost.accounts.length > 1) {
      return getBaseIconTooltipByIndex(metricIndex);
    }

    switch (account.type) {
      case SocialPlatform.FACEBOOK:
      case SocialPlatform.FACEBOOK_PROFILE:
      case SocialPlatform.FACEBOOK_PAGE:
      case SocialPlatform.FACEBOOK_GROUP:
        return getFacebookIconTooltipByIndex(metricIndex);
      case SocialPlatform.INSTAGRAM:
        return getInstagramIconTooltipByIndex(metricIndex, shouldShowReelMetricsForInstagram());
      case SocialPlatform.YOUTUBE:
        return getYouTubeIconTooltipByIndex(metricIndex);
      case SocialPlatform.TIKTOK:
        return getTikTokIconTooltipByIndex(metricIndex);
      case SocialPlatform.LINKEDIN:
      case SocialPlatform.LINKEDIN_PROFILE:
      case SocialPlatform.LINKEDIN_PAGE:
        return getLinkedInIconTooltipByIndex(metricIndex);
      case SocialPlatform.TWITTER:
        return getTwitterIconTooltipByIndex(metricIndex);
      case SocialPlatform.PINTEREST:
        return getPinterestIconTooltipByIndex(metricIndex);
      default:
        return '';
    }
  };

  const getMetricIconTooltip = (metricIndex: number): ReactElement => {
    const icon = (
      <Icon
        type={IconType.FLAT}
        size={IconSize.SIZE_ICON_20}
        className={isDefaultSelection() && socialPost.accounts.length > 1 ? styles.iconStyles : ''}
        flatIconType={{color: getFlatIconColor(), type: FlatIconColorType.DEFAULT}}
        shape={IconShape.CIRCLE}
        icon={getMetricIcon(metricIndex)}
      />
    );

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        delay={{show: 50, hide: 100}}
        overlay={
          <Tooltip id="tooltip-icon" className="spacing-m-b-3 _pointer-events-none">
            {getIconTooltipByAccountSelection(metricIndex)}
          </Tooltip>
        }
        show={undefined}
        defaultShow={undefined}
        flip={undefined}
        onHide={undefined}
        onToggle={undefined}
        popperConfig={undefined}
        target={undefined}
      >
        {icon}
      </OverlayTrigger>
    );
  };

  const getAccountWiseTooltip = (metricIndex: number): ReactElement => {
    const metricKey = metricsBase[metricIndex];
    const accountMetricsList: ReactElement[] = [];

    socialPost.accounts.forEach((account) => {
      let num = 0;
      const accountId = account.id;
      if (socialPost.metrics && socialPost.metrics[accountId]) {
        const metrics = socialPost.metrics[accountId];
        num = metrics && metrics[metricKey] ? metrics[metricKey] : 0;
        const accountName = getReadableAccounts(account.type);
        const detail = `${accountName}: ${getTooltipReadableMetrics(num)}`;

        accountMetricsList.push(
          <div className={styles.accountTooltip} id={`account-breakdown-${accountId}`}>
            {detail}
          </div>
        );
      }
    });

    return <div className={styles.tooltipContainer}>{accountMetricsList}</div>;
  };

  const shouldShowNA = (metricIndex: number): boolean => {
    const likeIndex = 0;
    const commentIndex = 1;
    const shareIndex = 4;

    if (isDefaultSelection() && socialPost.accounts.length !== 1) {
      return false;
    }

    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    const isInstagramMultiPosting = account.type === SocialPlatform.INSTAGRAM && isMultiPosting();

    if (!isInstagramMultiPosting) {
      return false;
    }

    return metricIndex === likeIndex || metricIndex === commentIndex || metricIndex === shareIndex;
  };

  const getMetricValueTooltip = (readableMetric: string, metricIndex: number): ReactElement => {
    const metricElementDefault = (
      <div className={styles.metricDetailsContainer}>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="top"
          delay={{show: 50, hide: 100}}
          overlay={
            <Tooltip id="tooltip-icon" className="spacing-m-b-2 _pointer-events-none">
              {getAccountWiseTooltip(metricIndex)}
            </Tooltip>
          }
          show={undefined}
          defaultShow={undefined}
          flip={undefined}
          onHide={undefined}
          onToggle={undefined}
          popperConfig={undefined}
          target={undefined}
        >
          <div className={styles.metricNumberContainer}>
            <Text val={`${shouldShowNA(metricIndex) ? window.i18next.t('pmwjs_na') : readableMetric}`} bold size={TextSize.SMALL} className="content-body" />
          </div>
        </OverlayTrigger>
        <Text val={getMetricText(metricIndex)} size={TextSize.XXSMALL} className="content-sub-text" />
      </div>
    );

    if (isDefaultSelection() && socialPost.accounts.length !== 1) {
      return metricElementDefault;
    }

    return (
      <div className={styles.metricDetailsContainer}>
        <Text val={`${shouldShowNA(metricIndex) ? window.i18next.t('pmwjs_na') : readableMetric}`} bold size={TextSize.SMALL} className="content-body" />
        <Text val={getMetricText(metricIndex)} size={TextSize.XXSMALL} className="content-sub-text" />
      </div>
    );
  };

  const getMetric = (metricIndex: number): ReactElement => {
    const metricMeta = getMetricMeta(metricIndex);
    const readableMetric = getReadableMetrics(metricMeta);

    return (
      <div key={`metric-container-${metricIndex}`} className={`${styles.metricWrapper} _fit-width _fit-height`}>
        {getMetricIconTooltip(metricIndex)}
        {getMetricValueTooltip(readableMetric, metricIndex)}
      </div>
    );
  };

  const getRowByIndex = (index: number): ReactElement => {
    const row = [];

    for (let j = 0; j < numberMetricsPerRow; j++) {
      const metricsIndex = numberMetricsPerRow * index + j;
      if (metricsIndex < getNumberMetrics()) {
        const metric = getMetric(metricsIndex);
        row.push(metric);
      }
    }

    return (
      <div key={`metrics-row-${index}`} className={styles.metricsGridRow}>
        {row}
      </div>
    );
  };

  const getNumberMetrics = (): number => {
    if (isDefaultSelection() && socialPost.accounts.length > 1) {
      return metricsBase.length;
    }

    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    switch (account.type) {
      case SocialPlatform.FACEBOOK_PROFILE:
      case SocialPlatform.FACEBOOK:
      case SocialPlatform.FACEBOOK_PAGE:
      case SocialPlatform.FACEBOOK_GROUP:
        return metricsFacebook.length;

      case SocialPlatform.TWITTER:
        return metricsTwitter.length;

      case SocialPlatform.INSTAGRAM:
        if (shouldShowReelMetricsForInstagram()) {
          return metricsInstagramVideo.length;
        }
        return metricsInstagramPost.length;

      case SocialPlatform.LINKEDIN_PAGE:
        return metricsLinkedInPage.length;

      case SocialPlatform.PINTEREST:
        return metricsPinterest.length;

      case SocialPlatform.YOUTUBE:
        return metricsYouTube.length;

      case SocialPlatform.TIKTOK:
        return metricsTikTok.length;

      default:
        return 0;
    }
  };

  const getGrid = (): ReactElement[] => {
    const nMetrics = getNumberMetrics();
    const nRows = ceil(nMetrics / numberMetricsPerRow);
    const rows = [];

    for (let i = 0; i < nRows; i++) {
      const row = getRowByIndex(i);
      rows.push(row);
    }

    return rows;
  };

  const shouldShowMetrics = (): boolean => {
    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;

    if (!socialPost.metrics) {
      return false;
    }

    if (isDefaultSelection()) {
      return true;
    }

    const accountId = account.id;
    const metricsForIthAccount = socialPost.metrics[accountId];

    if (!metricsForIthAccount) {
      return false;
    }

    return true;
  };

  const getMetricsGrid = (): ReactElement => {
    return <div className={styles.metricsGrid}>{getGrid()}</div>;
  };

  const getIconName = (guidelineType: SocialPostUserGuidelines): string => {
    switch (guidelineType) {
      case SocialPostUserGuidelines.ERROR:
        return 'icon-exclamation-triangle';

      case SocialPostUserGuidelines.PERMISSION_PENDING:
        return 'icon-ban';

      case SocialPostUserGuidelines.UPGRADE_PREMIUM:
        return 'icon-bar-chart';

      case SocialPostUserGuidelines.PRIVATE_POSTS:
        return 'icon-globe-2';

      default:
        return 'icon-bar-chart';
    }
  };

  const getFlatIconType = (guidelineType: SocialPostUserGuidelines): FlatIconType => {
    switch (guidelineType) {
      case SocialPostUserGuidelines.ERROR:
        return {color: FlatIconColor.DANGER, type: FlatIconColorType.DEFAULT};

      case SocialPostUserGuidelines.UPGRADE_PREMIUM:
        return {
          color: FlatIconColor.PREMIUM,
          type: FlatIconColorType.DEFAULT,
        };

      default:
        return {
          color: FlatIconColor.NEUTRAL,
          type: FlatIconColorType.DEFAULT,
        };
    }
  };

  const getUserGuidelineIcon = (guidelineType: SocialPostUserGuidelines): ReactElement => {
    if (userGuidelineType === SocialPostUserGuidelines.PERFORMANCE_LIMITATIONS || userGuidelineType === SocialPostUserGuidelines.STORY_PERFORMANCE_LIMITATIONS) {
      return (
        <div className={`${styles.emojiContainer}`}>
          <Text val={`${window.i18next.t('pmwjs_performance_metrics_performance_limitation_emoji')}`} />
        </div>
      );
    }

    return (
      <Icon
        className="spacing-m-b-3"
        icon={getIconName(guidelineType)}
        shape={IconShape.CIRCLE}
        size={IconSize.SIZE_ICON_24}
        type={IconType.FLAT}
        flatIconType={getFlatIconType(guidelineType)}
      />
    );
  };

  const getPermissionPendingTypeText = (currentAccount: SocialAccount): string => {
    if (isDefaultSelection()) {
      return window.i18next.t('pmwjs_performance_metrics_permission_required_general');
    }

    return window.i18next.t('pmwjs_performance_metrics_permission_required', {platform: getReadableAccounts(currentAccount.type)});
  };

  const getUserGuidelineText = (guidelineType: SocialPostUserGuidelines): string => {
    const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activeAccountIndex);
    const {account} = publishingParamsInformation;
    const {platformMediaType} = publishingParamsInformation;

    if (!socialPost.publishingParams[account.id]) {
      return '';
    }

    const currentAccount = account;
    const currentPublishingParams = socialPost.publishingParams[currentAccount.id][platformMediaType];

    if (!currentPublishingParams) {
      return '';
    }

    switch (guidelineType) {
      case SocialPostUserGuidelines.ERROR:
        return currentPublishingParams.publishErrorMessage ? currentPublishingParams.publishErrorMessage : window.i18next.t('pmwjs_account_expired_guideline');
      case SocialPostUserGuidelines.PRIVATE_POSTS:
        return window.i18next.t('pmwjs_performance_metrics_private_posts');
      case SocialPostUserGuidelines.POPULATING:
        return window.i18next.t('pmwjs_performance_metrics_populating', {platform: getReadableAccounts(currentAccount.type)});
      case SocialPostUserGuidelines.UPGRADE_PREMIUM:
        return window.i18next.t('pmwjs_performance_metrics_upgrade');
      case SocialPostUserGuidelines.PERMISSION_PENDING:
        return getPermissionPendingTypeText(currentAccount);
      case SocialPostUserGuidelines.UNPUBLISHED_POST:
        return window.i18next.t('pmwjs_performance_metrics_published');
      case SocialPostUserGuidelines.PERFORMANCE_LIMITATIONS:
        return window.i18next.t('pmwjs_performance_metrics_performance_limitations', {platform: getReadableAccounts(currentAccount.type)});
      case SocialPostUserGuidelines.STORY_PERFORMANCE_LIMITATIONS:
        return window.i18next.t('pmwjs_performance_metrics_story_performance_limitations');
      default:
        return '';
    }
  };

  const getUserGuideline = (): ReactElement => {
    return (
      <div className={`${styles.userGuidelineContainer} _full-width`}>
        {getUserGuidelineIcon(userGuidelineType)}
        <Text dangerouslySetInnerHTML className={styles.guidelineStyles} size={TextSize.XSMALL} val={getUserGuidelineText(userGuidelineType)} />
      </div>
    );
  };

  const getPerformanceHeading = (): ReactElement => {
    return <Text val={window.i18next.t('pmwjs_performance')} className={`${shouldShowMetrics() ? 'spacing-m-b-3' : ''} content-body`} bold size={TextSize.SMALL} />;
  };

  const getLastUpdatedOn = (): ReactElement | null => {
    if (userGuidelineType !== SocialPostUserGuidelines.NO_GUIDELINES || !socialPost.metricsUpdatedOn) {
      return null;
    }

    const timeFromNow = getTimeToOrFrom(getDateFromUnixTimestamp(socialPost.metricsUpdatedOn));

    return (
      <div className={styles.lastUpdatedContainer}>
        <Text val={window.i18next.t('pmwjs_last_updated_on', {timeDetails: timeFromNow})} className={`${styles.lastUpdatedText} content-sub-text`} size={TextSize.SMALL} />
      </div>
    );
  };

  return (
    <div className={`${styles.metricsWrapper} _full-width _full-height`}>
      {userGuidelineType === SocialPostUserGuidelines.ERROR ? null : getPerformanceHeading()}
      {userGuidelineType === SocialPostUserGuidelines.NO_GUIDELINES ? getMetricsGrid() : getUserGuideline()}
      {getLastUpdatedOn()}
    </div>
  );
}

export default SocialPostDialogMetricsGrid;
