import {readCookie} from '@Utils/cookie.util';
import {showSaveReminder} from '@Components/save-reminder-notification/save-reminder-notification.reducer';
import {SAVE_REMINDER_COOKIE_KEY, SaveReminderCookieValues} from '@Components/save-reminder-notification/save-reminder-notification.types';

export const showSaveReminderNotification = (): void => {
  const cookie = readCookie(SAVE_REMINDER_COOKIE_KEY);
  if (cookie !== SaveReminderCookieValues.HIDE_REMINDER) {
    window.PMW.redux.store.dispatch(showSaveReminder());
  }
};
