import type {PostState} from '@Components/social-media/post.vo';
import {SocialPostUserGuidelines} from '@Components/social-media/components/social-post-dialog-metrics-grid/social-post-dialog-metrics-grid';
import {useAppSelector} from '@/hooks';

export const useSocialPostsDialogState = (): PostState => {
  return useAppSelector((state) => {
    if (state.socialPostDialog.publishingStatus.length === 0 || state.socialPostDialog.currentAccountIndex === -1) {
      return state.socialPostDialog.currentPostState;
    }

    const currentIndex = state.socialPostDialog.currentAccountIndex;
    return state.socialPostDialog.publishingStatus[currentIndex];
  });
};

export const useSocialPostDialogScheduleDetails = (): Date => {
  return useAppSelector((state) => {
    return state.socialPostDialog.currentScheduleDetails;
  });
};

export const useSocialPostDialogIsDatePicker = (): boolean => {
  return useAppSelector((state) => {
    return state.socialPostDialog.isDatePickerView;
  });
};

export const useCurrentAccountIndex = (): number => {
  return useAppSelector((state) => {
    return state.socialPostDialog.currentAccountIndex;
  });
};

export const useIsErrorState = (): null | PostState.SCHEDULE_ERROR | PostState.ERROR => {
  return useAppSelector((state) => {
    return state.socialPostDialog.isErrorState;
  });
};

export const useSocialPostDialogPublishingStatus = (): PostState[] => {
  return useAppSelector((state) => {
    return state.socialPostDialog.publishingStatus;
  });
};

export const useShouldShowBottomShadow = (): boolean => {
  return useAppSelector((state) => {
    return state.socialPostDialog.shouldShowBottomShadow;
  });
};

export const useUserGuidelineType = (): SocialPostUserGuidelines => {
  return useAppSelector((state) => {
    return state.socialPostDialog.userGuidelineType;
  });
};

export const useIsPremiumCtaView = (): boolean => {
  return useAppSelector((state) => {
    return state.socialPostDialog.userGuidelineType === SocialPostUserGuidelines.UPGRADE_PREMIUM;
  });
};
