import type {
  FacebookMetrics,
  InstagramMetrics,
  LinkedInMetrics,
  MetricLabels,
  PinterestMetrics,
  SocialPlatformMetrics,
  TikTokMetrics,
  TwitterMetrics,
  YouTubeMetrics,
} from '@Components/social-media/social-platform-metrics.types';
import {
  getMetricIconByKey,
  getMetricIconTooltipbyKey,
  getReadableMetricByKey,
  getYouTubeTooltipbyKey,
  metricsBase,
  metricsFacebook,
  metricsInstagramPost,
  metricsInstagramVideo,
  metricsLinkedInPage,
  metricsPinterest,
  metricsTikTok,
  metricsTwitter,
  metricsYouTube,
} from '@Components/social-media/social-platform-metrics.types';
import type {SocialPost} from '@Components/social-media/post.vo';
import {PostState} from '@Components/social-media/post.vo';
import type {MetricMeta} from '@Components/social-media/components/social-post-dialog-metrics-grid/social-post-dialog-metrics-grid';

export const getBaseMetricIconByIndex = (metricIndex: number): string => {
  const baseMetricKey = metricsBase[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(baseMetricKey);
};

export const getFacebookMetricIconByIndex = (metricIndex: number): string => {
  const facebookMetricKey = metricsBase[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(facebookMetricKey);
};

export const getLinkedinMetricIconByIndex = (metricIndex: number): string => {
  const linkedinMetricKey = metricsLinkedInPage[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(linkedinMetricKey);
};

export const getTwitterMetricIconByIndex = (metricIndex: number): string => {
  const twitterMetricKey = metricsBase[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(twitterMetricKey);
};

export const getTikTokMetricIconByIndex = (metricIndex: number): string => {
  const tiktokMetricKey = metricsTikTok[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(tiktokMetricKey);
};

export const getPinterestMetricIconByIndex = (metricIndex: number): string => {
  const pinterestMetricKey = metricsPinterest[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(pinterestMetricKey);
};

export const getYouTubeMetricIconByIndex = (metricIndex: number): string => {
  const youtubeMetricKey = metricsYouTube[metricIndex] as keyof MetricLabels;
  return getMetricIconByKey(youtubeMetricKey);
};

export const getInstagramMetricIconByIndex = (metricIndex: number, isVideo: boolean): string => {
  const instagramMetricKey = isVideo ? (metricsInstagramVideo[metricIndex] as keyof MetricLabels) : (metricsInstagramPost[metricIndex] as keyof MetricLabels);
  return getMetricIconByKey(instagramMetricKey);
};

export const getFacebookMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number): number => {
  const metricKey = metricsFacebook[index];
  const facebookMetrics = platformMetrics as FacebookMetrics;
  return facebookMetrics[metricKey];
};

export const getLinkedinMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number): number => {
  const metricKey = metricsLinkedInPage[index];
  const linkedinMetrics = platformMetrics as LinkedInMetrics;
  return linkedinMetrics[metricKey];
};

export const getTwitterMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number): number => {
  const metricKey = metricsTwitter[index];
  const twitterMetrics = platformMetrics as TwitterMetrics;
  return twitterMetrics[metricKey];
};

export const getPinterestMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number): number => {
  const metricKey = metricsPinterest[index];
  const pinterestMetrics = platformMetrics as PinterestMetrics;
  return pinterestMetrics[metricKey];
};

export const getTikTokMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number): number => {
  const metricKey = metricsTikTok[index];
  const tiktokMetrics = platformMetrics as TikTokMetrics;
  return tiktokMetrics[metricKey];
};

export const getYouTubeMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number): number => {
  const metricKey = metricsYouTube[index];
  const youtubeMetrics = platformMetrics as YouTubeMetrics;
  return youtubeMetrics[metricKey];
};

export const getInstagramMetricsByIndex = (platformMetrics: SocialPlatformMetrics, index: number, isVideo: boolean): number => {
  const metricKey = getInstagramMetricsKey(isVideo, index);

  if (metricKey === undefined) {
    return 0;
  }

  const instagramMetrics = platformMetrics as InstagramMetrics;
  return instagramMetrics[metricKey];
};

export const getInstagramMetricsKey = (isVideo: boolean, index: number): keyof InstagramMetrics => {
  return isVideo ? metricsInstagramVideo[index] : metricsInstagramPost[index];
};

export const getBaseMetricDescriptionByIndex = (metricIndex: number): string => {
  const baseMetricKey = metricsBase[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(baseMetricKey, false);
};

export const getFacebookMetricDescriptionByIndex = (metricIndex: number): string => {
  const facebookMetricKey = metricsFacebook[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(facebookMetricKey, true);
};

export const getLinkedInMetricDescriptionByIndex = (metricIndex: number): string => {
  const linkedinMetricKey = metricsLinkedInPage[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(linkedinMetricKey, false);
};

export const getTwitterMetricDescriptionByIndex = (metricIndex: number): string => {
  const twitterMetricKey = metricsTwitter[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(twitterMetricKey, false);
};

export const getYouTubeMetricDescriptionByIndex = (metricIndex: number): string => {
  const youtubeMetricKey = metricsYouTube[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(youtubeMetricKey, false);
};

export const getPinterestMetricDescriptionByIndex = (metricIndex: number): string => {
  const pinterestMetricKey = metricsPinterest[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(pinterestMetricKey, false);
};

export const getTikTokMetricDescriptionByIndex = (metricIndex: number): string => {
  const tiktokMetricKey = metricsTikTok[metricIndex] as keyof MetricLabels;
  return getReadableMetricByKey(tiktokMetricKey, false);
};

export const getInstagramDescriptionByIndex = (metricIndex: number, isVideo: boolean): string => {
  const instagramMetricKey = isVideo ? (metricsInstagramVideo[metricIndex] as keyof MetricLabels) : (metricsInstagramPost[metricIndex] as keyof MetricLabels);
  return getReadableMetricByKey(instagramMetricKey, false);
};

export const getBaseIconTooltipByIndex = (metricIndex: number): string => {
  const baseMetricKey = metricsBase[metricIndex] as keyof MetricLabels;
  return getMetricIconTooltipbyKey(baseMetricKey, true, false);
};

export const getFacebookIconTooltipByIndex = (metricIndex: number): string => {
  const facebookMetricKey = metricsFacebook[metricIndex] as keyof MetricLabels;
  return getMetricIconTooltipbyKey(facebookMetricKey, false, true);
};

export const getInstagramIconTooltipByIndex = (metricIndex: number, isVideo: boolean): string => {
  const instagramMetricKey = isVideo ? (metricsInstagramVideo[metricIndex] as keyof MetricLabels) : (metricsInstagramPost[metricIndex] as keyof MetricLabels);
  return getMetricIconTooltipbyKey(instagramMetricKey, false, false);
};

export const getLinkedInIconTooltipByIndex = (metricIndex: number): string => {
  const linkedinMetricKey = metricsLinkedInPage[metricIndex] as keyof MetricLabels;
  return getMetricIconTooltipbyKey(linkedinMetricKey, false, false);
};

export const getYouTubeIconTooltipByIndex = (metricIndex: number): string => {
  const youtubeMetricKey = metricsYouTube[metricIndex] as keyof MetricLabels;
  return getYouTubeTooltipbyKey(youtubeMetricKey);
};

export const getPinterestIconTooltipByIndex = (metricIndex: number): string => {
  const pinterestMetricKey = metricsPinterest[metricIndex] as keyof MetricLabels;
  return getMetricIconTooltipbyKey(pinterestMetricKey, false, false);
};

export const getTikTokIconTooltipByIndex = (metricIndex: number): string => {
  const tiktokMetricKey = metricsTikTok[metricIndex] as keyof MetricLabels;
  return getMetricIconTooltipbyKey(tiktokMetricKey, false, false);
};

export const getTwitterIconTooltipByIndex = (metricIndex: number): string => {
  const twitterMetricKey = metricsTwitter[metricIndex] as keyof MetricLabels;
  return getMetricIconTooltipbyKey(twitterMetricKey, false, false);
};

export const truncateDecimalsIfNeeded = (value: string): string => {
  if (value.endsWith('.00')) {
    return `${value.slice(0, -3)}`;
  }

  if (value.endsWith('0')) {
    return `${value.slice(0, -1)}`;
  }

  return value;
};

const isNumberADecimal = (value: number): boolean => {
  return value - Math.floor(value) !== 0;
};

export const getReadableMetrics = (metricMeta: MetricMeta): string => {
  const {value} = metricMeta;

  if (metricMeta.type === 'numEngagement') {
    const percentageValue = Math.ceil(value * 100);
    return `${percentageValue}%`;
  }

  if (value >= 1e6) {
    const formattedValue = (value / 1e6).toFixed(2);
    return `${truncateDecimalsIfNeeded(formattedValue)}M`;
  }

  if (value >= 1e3) {
    const formattedValue = (value / 1e3).toFixed(2);
    return `${truncateDecimalsIfNeeded(formattedValue)}K`;
  }

  if (isNumberADecimal(value)) {
    return `${value.toFixed(2)}`;
  }

  return value.toString();
};

export const getTooltipReadableMetrics = (value: number): string => {
  return value.toLocaleString('en-US');
};

export const getNumErrorAccounts = (post: SocialPost): number => {
  let count = 0;

  if (post.state === PostState.PUBLISHED && post.publishingParams) {
    const accountIds = Object.keys(post.publishingParams);

    accountIds.forEach((accountId): void => {
      const postPublishingParams = post.publishingParams[accountId].POST;
      const storyPublishingParams = post.publishingParams[accountId].STORIES;
      const reelPublishingParams = post.publishingParams[accountId].REELS;

      if (!postPublishingParams && !storyPublishingParams && !reelPublishingParams) {
        console.error('No Publishing Params found for any MediaType');
        return;
      }

      if (postPublishingParams?.state === PostState.ERROR || storyPublishingParams?.state === PostState.ERROR || reelPublishingParams?.state === PostState.ERROR) {
        count += 1;
      }
    });
  }

  return count;
};
