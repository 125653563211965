import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface SaveReminderNotificationProps {
  show: boolean;
  isOnboardingPopupShowing: boolean;
}

const initialState: SaveReminderNotificationProps = {
  show: false,
  isOnboardingPopupShowing: false,
};

const saveReminderSlice = createSlice({
  name: 'messageGrowl',
  initialState,
  reducers: {
    showSaveReminder: (state) => {
      state.show = true;
    },
    hideSaveReminder: (state) => {
      state.show = false;
    },
    toggleOnboardingPopupState: (state, action: PayloadAction<boolean>) => {
      state.isOnboardingPopupShowing = action.payload;
    },
  },
});

export const {showSaveReminder, hideSaveReminder, toggleOnboardingPopupState} = saveReminderSlice.actions;
export const saveReminderReducer = saveReminderSlice.reducer;
